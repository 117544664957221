'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

function hyper(h) {
    var children = [];
    for (var _i = 1; _i < arguments.length; _i++) {
        children[_i - 1] = arguments[_i];
    }
    var parts = h.split(/(?=[.#])/);
    var tag = "div";
    if (parts[0][0] !== "#" && parts[0][0] !== ".") {
        tag = parts[0];
        parts = parts.slice(1);
    }
    var elem = document.createElement(tag);
    for (var i = 0; i < parts.length; i++) {
        switch (parts[i][0]) {
            case "#":
                elem.setAttribute("id", parts[i].substr(1));
                break;
            case ".":
                elem.classList.add(parts[i].substr(1));
                break;
        }
    }
    function handleChildren(children) {
        children.forEach(function (child) {
            if (Array.isArray(child)) {
                handleChildren(child);
            }
            else if (child instanceof Node) {
                elem.appendChild(child);
            }
            else if (typeof child === "object") {
                for (var key in child) {
                    var item = child[key];
                    if (typeof item === "string") {
                        elem.setAttribute(key, item);
                    }
                }
            }
            else if (typeof child === "string" || typeof child === "number") {
                elem.appendChild(document.createTextNode(child.toString()));
            }
        });
    }
    handleChildren(children);
    return elem;
}

var leftArrow = '<svg width="200" height="200" viewBox="0 0 52.917 52.917" xmlns="http://www.w3.org/2000/svg"><path style="fill:none;stroke:#000;stroke-width:8.66;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="M37.042 5.292 15.875 26.458l21.167 21.167"/></svg>';
var rightArrow = '<svg width="200" height="200" viewBox="0 0 52.917 52.917" xmlns="http://www.w3.org/2000/svg"><path style="fill:none;stroke:#000;stroke-width:8.66;stroke-linecap:round;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="m15.875 5.292 21.167 21.166-21.167 21.167"/></svg>';
var xIcon = '<svg width="50" height="50" viewBox="0 0 13.229 13.229" xmlns="http://www.w3.org/2000/svg"><path style="fill:none;stroke:#000;stroke-width:2;stroke-linecap:butt;stroke-linejoin:bevel;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="m2.646 10.583 7.937-7.937"/><path style="fill:none;stroke:#000;stroke-width:2;stroke-linecap:butt;stroke-linejoin:bevel;stroke-opacity:1;stroke-miterlimit:4;stroke-dasharray:none" d="M10.583 10.583 2.646 2.646"/></svg>';
var TRANSITION_TIME = 500;
function createGalleryView() {
    var viewer = hyper("div.viewer.hidden", hyper("div.viewer-overlay"), hyper("div.viewer-box", hyper("div.viewer-back.hidden", hyper("a", { href: "#" })), hyper("div.viewer-contents", hyper("div.img-container"), hyper("div.img-description", hyper("div.desc-text"), hyper("div.close-icon", hyper("a", { href: "#" }))), hyper("div.loading-spinner", hyper("div.lds-ellipsis", hyper("div"), hyper("div"), hyper("div"), hyper("div")))), hyper("div.viewer-forward.hidden", hyper("a", { href: "#" }))));
    var leftArrowSVG = leftArrow.replace("stroke:#000", "").replace("width=\"200\"", "").replace("height=\"200\"", "");
    var rightArrowSVG = rightArrow.replace("stroke:#000", "").replace("width=\"200\"", "").replace("height=\"200\"", "");
    var viewerBack = viewer.querySelector(".viewer-back");
    var viewerForward = viewer.querySelector(".viewer-forward");
    viewerBack.firstElementChild.innerHTML = leftArrowSVG;
    viewerForward.firstElementChild.innerHTML = rightArrowSVG;
    var viewerOverlay = viewer.querySelector(".viewer-overlay");
    viewerOverlay.addEventListener("click", function () {
        hide();
    });
    var viewerContents = viewer.querySelector(".viewer-contents");
    var imgContainer = viewer.querySelector(".img-container");
    var imgDescElem = viewer.querySelector(".img-description");
    var descTextElem = imgDescElem.querySelector(".desc-text");
    var closeButtonElem = imgDescElem.querySelector(".close-icon a");
    closeButtonElem.addEventListener("click", function (ev) {
        ev.preventDefault();
        hide();
    });
    closeButtonElem.innerHTML = xIcon.replace("stroke:#000", "").replace("stroke:#000", "").replace("width=\"50\"", "").replace("height=\"50\"", "");
    var loadedImage = new Image();
    imgContainer.appendChild(loadedImage);
    var imgDimensions = { width: 1, height: 1 };
    var imageFadeTimeout;
    function loadImg(img) {
        loadedImage = new Image();
        loadedImage.src = img.src;
        viewerContents.classList.add("loading");
        descTextElem.innerText = img.desc || "";
        var startTime = Date.now();
        loadedImage.onload = function () {
            imgDimensions.width = loadedImage.width;
            imgDimensions.height = loadedImage.height;
            resizeView();
            var remainingTime = Math.max(TRANSITION_TIME, TRANSITION_TIME - (Date.now() - startTime));
            clearTimeout(imageFadeTimeout);
            imageFadeTimeout = setTimeout(function () {
                viewerContents.classList.remove("loading");
                imgContainer.firstChild.replaceWith(loadedImage);
            }, remainingTime);
        };
    }
    function resizeView() {
        var imgRatio = imgDimensions.height > 0 ? (imgDimensions.width / imgDimensions.height) : 1;
        var screenRatio = window.innerWidth / window.innerHeight;
        if (imgRatio > screenRatio) {
            var targetWidth = Math.round(window.innerWidth * 0.8);
            var targetHeight = Math.round(targetWidth * (1 / imgRatio));
            imgContainer.style.width = "".concat(targetWidth, "px");
            imgContainer.style.height = "".concat(targetHeight, "px");
            imgDescElem.style.width = "".concat(targetWidth, "px");
        }
        else {
            var targetHeight = Math.round(window.innerHeight * 0.8);
            var targetWidth = Math.round(targetHeight * (imgRatio));
            imgContainer.style.height = "".concat(targetHeight, "px");
            imgContainer.style.width = "".concat(Math.round(targetHeight * (imgRatio)), "px");
            imgDescElem.style.width = "".concat(targetWidth, "px");
        }
    }
    document.body.appendChild(viewer);
    window.addEventListener("resize", function () {
        resizeView();
    });
    var imageSet = [];
    var imageSetIndex = 0;
    viewerBack.firstElementChild.addEventListener("click", function (ev) {
        ev.preventDefault();
        prevImage();
    });
    viewerForward.firstElementChild.addEventListener("click", function (ev) {
        ev.preventDefault();
        nextImage();
    });
    var updateArrowVis = function () {
        if (imageSetIndex === 0) {
            viewerBack.classList.add("hidden");
        }
        else {
            viewerBack.classList.remove("hidden");
        }
        if (imageSetIndex === imageSet.length - 1) {
            viewerForward.classList.add("hidden");
        }
        else {
            viewerForward.classList.remove("hidden");
        }
    };
    var nextImage = function () {
        var newIndex = imageSetIndex + 1;
        if (newIndex < imageSet.length) {
            imageSetIndex = newIndex;
            loadImg(imageSet[imageSetIndex]);
            updateArrowVis();
        }
    };
    var prevImage = function () {
        var newIndex = imageSetIndex - 1;
        if (newIndex >= 0) {
            imageSetIndex = newIndex;
            loadImg(imageSet[imageSetIndex]);
            updateArrowVis();
        }
    };
    resizeView();
    var loadSet = function (setItems, index) {
        if (index === void 0) { index = 0; }
        viewer.classList.remove("hidden");
        imageSet = setItems;
        imageSetIndex = index;
        loadImg(imageSet[imageSetIndex]);
        updateArrowVis();
    };
    var hide = function () {
        viewer.classList.add("hidden");
    };
    return {
        elem: viewer,
        next: nextImage,
        prev: prevImage,
        loadSet: loadSet,
        hide: hide
    };
}

exports.createGalleryView = createGalleryView;
