import "../lib/gallery-view/style.css";
import {createGalleryView} from "../lib/gallery-view";

const app = document.querySelector("#app");

const header = app.querySelector(".header");

const navbar = app.querySelector(".navbar");

const titleLink: HTMLAnchorElement = header.querySelector("a");

const aboutLink: HTMLAnchorElement = navbar.querySelector(".about-link a");
const worksLink: HTMLAnchorElement = navbar.querySelector(".works-link a");
const contactLink: HTMLAnchorElement = navbar.querySelector(".contact-link a");

const pages = app.querySelector(".pages");

let pageIndex = 0;

function setPageClasses(index: number) {
    pages.classList.remove("page-0");
    pages.classList.remove("page-1");
    pages.classList.remove("page-2");
    pages.classList.remove("page-3");
    pages.classList.add("page-" + index);
}

function selectPage(index: number) {
    if(index === 0){
        header.classList.remove("minimize");
        setPageClasses(index);
    } else {
        setPageClasses(index);
        header.classList.add("minimize");
    }
    pageIndex = index;
}

titleLink.addEventListener("click", (ev) => {
    //ev.preventDefault();
    selectPage(0);
})

aboutLink.addEventListener("click", (ev) => {
    //ev.preventDefault();
    selectPage(1);
});

worksLink.addEventListener("click", (ev) => {
    //ev.preventDefault();
    selectPage(2);
});

contactLink.addEventListener("click", (ev) => {
    //ev.preventDefault();
    selectPage(3);
});

window.onpopstate = () => {
    updatePageLocation();
}

function updatePageLocation() {
    galleryView.hide();
    switch(document.location.hash){
        case "":
            selectPage(0);
            break;
        case "#about":
            selectPage(1);
            break;
        case "#works":
            selectPage(2);
            break;
        case "#contact":
            selectPage(3);
            break;
    }
}

const previewClose: HTMLDivElement = app.querySelector(".preview-close");

const images: {[id: string]: {thumb: string, src: string, desc: string}} = {
    officeDay: {
        thumb: require("url:./assets/buildings/OfficeDay.png?as=jpg&width=400"),
        src: require("url:./assets/buildings/OfficeDay.png?as=jpg"),
        desc: "Daytime office render using Blender."
    },
    officeNight: {
        thumb: require("url:./assets/buildings/OfficeNight.png?as=jpg&width=400"),
        src: require("url:./assets/buildings/OfficeNight.png?as=jpg"),
        desc: "Nighttime office render using Blender."
    },
    modernHouse: {
        thumb: require("url:./assets/buildings/ModernHouse.png?as=jpg&width=400"),
        src: require("url:./assets/buildings/ModernHouse.png?as=jpg"),
        desc: "A modern house modelled in Sketchup using an image found online, and rendered using SUPodium for Sketchup."
    },
    modernHouseOverlay: {
        thumb: require("url:./assets/buildings/ModernHouseOverlay.png?as=jpg&width=400"),
        src: require("url:./assets/buildings/ModernHouseOverlay.png?as=jpg"),
        desc: "An overlay of the house render overtop of the original house photo."
    },
    factoryIso: {
        thumb: require("url:./assets/factory/FactoryIso.png?as=png&width=400"),
        src: require("url:./assets/factory/FactoryIso.png?as=png"),
        desc: "An isometric view of a factory designed and modeled in AutoCAD and Revit, and rendered in Blender."
    },
    factoryInterior: {
        thumb: require("url:./assets/factory/FactoryInterior.png?as=jpg&width=400"),
        src: require("url:./assets/factory/FactoryInterior.png?as=jpg"),
        desc: "An interior render of a factory designed and modeled in AutoCAD and Revit, and rendered in Blender. "
    },
    houseExploded: {
        thumb: require("url:./assets/3dprint/house_exploded.png?as=jpg&width=400"),
        src: require("url:./assets/3dprint/house_exploded.png?as=jpg"),
        desc: "An exploded view of a 3D print model of a house."
    },
    housePieces: {
        thumb: require("url:./assets/3dprint/house_pieces.jpg?as=jpg&width=400"),
        src: require("url:./assets/3dprint/house_pieces.jpg?as=jpg"),
        desc: "The 3D printed pieces of a house modelled in Sketchup."
    },
    prints3D: {
        thumb: require("url:./assets/3dprint/3d Prints.jpg?as=jpg&width=400"),
        src: require("url:./assets/3dprint/3d Prints.jpg?as=jpg"),
        desc: "A bunch of different models I 3D printed with a coin for scale."
    },
    backyardRender: {
        thumb: require("url:./assets/photogrammetry/BackyardCircleRender.png?as=png&width=400"),
        src: require("url:./assets/photogrammetry/BackyardCircleRender.png?as=png"),
        desc: "A 3D render of a model of stumps I created using photogrammetry."
    },
    backyardWire: {
        thumb: require("url:./assets/photogrammetry/BackyardWire.png?as=jpg&width=400"),
        src: require("url:./assets/photogrammetry/BackyardWire.png?as=jpg"),
        desc: "A wireframe render of a photoscan captured using Meshroom."
    },
    padogaRender: {
        thumb: require("url:./assets/3dprint/padoga_render.png?as=jpg&width=400"),
        src: require("url:./assets/3dprint/padoga_render.png?as=jpg"),
        desc: "A 3D render of a Padoga model I created for the chinese new year."
    },
    buildingElevation: {
        thumb: require("url:./assets/elevations/Building1.png?as=png&width=400"),
        src: require("url:./assets/elevations/Building1.png?as=png&width=2000"),
        desc: "An accurate to scale photo-elevation of an existing building."
    }
};

const imageIds: NodeListOf<HTMLImageElement> = app.querySelectorAll("img[data-gallery-id]");

const galleryView = createGalleryView();

const imageSets: {[key: string]:(typeof images[0])[]} = {};

imageIds.forEach(imageElem => {
    const group = imageElem.dataset.group;
    if(!imageSets[group]){
        imageSets[group] = [];
    }
    const index = imageSets[group].length;
    imageSets[group].push(images[imageElem.dataset.galleryId]);
    imageElem.addEventListener("click", () => {
        galleryView.loadSet(imageSets[group], index);
    });
});

const demoButton = document.getElementById("demo-button");
if(demoButton){
    demoButton.addEventListener("click", ev => {
        ev.preventDefault();
        const elem = document.querySelector("img[data-gallery-id=officeDay]") as HTMLImageElement;
        if(elem){
            elem.click();
        }
    });
}


updatePageLocation();